import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import {
  FaPhone,
  FaFacebookF,
  FaFacebookMessenger,
  FaInstagram,
  FaLine,
  FaFileAlt,
} from "react-icons/fa"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          mobile
          facebook
          instagram
          line
        }
      }
      map: file(relativePath: { eq: "map/map.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={`${data.site.siteMetadata.author} | ติดต่อเรา`} />
      <div className="container text-center mt-5 mb-4">
          <h1 >ติดต่อเรา</h1>
          <div className="line-center "></div>
      </div>
      <div className="container">
        <Img fluid={data.map.childImageSharp.fluid} className="" />
      </div>
      <div className="container mt-5">
        <div className="col-12">
        <h3 className="text-center">เดินทางโดย BTS </h3>
        <p className="title text-center">ซอยสุขุมวิท 101 (วัดธรรมมงคล) BTS ปุณวิถีทางออก 1 แล้วเดินเข้าซอยมาประมาณ 80 เมตร <br />สังเกตุทางซ้ายมือ ซอยปุณวิถี 3 ตึกบุญทิมา 4 ร้านตั้งอยู่ที่ชั้น 1 ค่ะ</p>
        <h3 className="mt-5 text-center">เดินทางโดย รถยนต์</h3>
        <p className="title text-center">ซอยสุขุมวิท 101 (วัดธรรมมงคล) ประมาณ 80 เมตรสังเกตุซ้ายมือ ซอยปุณวิถี 3 เลี้ยวเข้ามาด้านขวามือตึกบุญทิมา 4 <br />สามารถจอดรถฟรีรอบอาคาร ร้านตั้งอยู่ที่ ชั้น 1 ค่ะ</p>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
